<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="brand-logo justify-content-center align-items-center">
        <img :src="require('@/assets/images/logo/papirai-logo.svg')" height="25"/>
        <h2 class="brand-text ml-1">
          papir.ai
        </h2>
      </div>
      <b-col
          lg="7"
          class="d-none d-lg-flex align-items-center p-1">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-1">
          <b-img
              fluid
              :src="imgUrl"
              alt="Login V2"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="5"
             class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8"
               md="6"
               lg="12"
               class="px-xl-2 mx-auto">
          <b-card-title class="mb-1 font-weight-bolder"
                        title-tag="h2">
            {{ $t('organization.createFirstWorkspace') }}
          </b-card-title>
          <create-workspace :placeholder-name="getPlaceholderWorkspaceName"
                            @skip="$router.replace('/dashboard')" :enable-skip="true"/>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {BCardTitle, BCol, BImg, BRow, VBTooltip,} from 'bootstrap-vue'
import CreateWorkspace from '../Create/index';
import {email, required} from '@validations'
import {togglePasswordVisibility} from '@core/mixins/ui/forms'
import store from '@/store/index'
import {getUserData} from "@/auth/utils";
import {PossessiveSuffix} from "@/services/Utils";
import i18n from "@/libs/i18n";

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BImg,
    BCol,
    BCardTitle,
    CreateWorkspace
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: 'admin',
      userEmail: 'admin@demo.com',
      sideImg: require('@/assets/images/illustration/workspace.png'),
      loading: false,
      // validation rules
      required,
      email,
      getUserData
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/illustration/workspace.png')
        return this.sideImg
      }
      return this.sideImg
    },
    getPlaceholderWorkspaceName() {
      const possessive = new PossessiveSuffix()
      return possessive.word(getUserData().fullName).with(i18n.t('organization.context')).vocable
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
